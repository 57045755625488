<template>
  <div class="warp"
       v-loading="Loading"
       element-loading-text="拼命加载中"
       element-loading-spinner="el-icon-loading"
       element-loading-background="rgba(0, 0, 0, 0.8)">
    <div style="background:#932519;text-align:center;">
      <div><span>当前角色：</span><span v-if="info.charac_no >= 1">{{ info.charac_name }}</span><a class="logout"
           href="javascript:;"
           @click="LoginDisplay = 'display:block'"
           style="color:#989898;">《登录|切换》</a></div>
    </div>
    <div class="main">
      <div style="text-align:center;margin-top:40px;color:#ffbe77;">
        <h4>时间：{{ info.on_off }}</h4>
      </div>
      <!-- 礼包区 -->
      <div class="jltop"
           style="height:130px;">
        <ul>
          <!-- 每日登陆签到礼包 -->
          <li style="position:relative;left:92px;">
            <div>
              <img class="lj jl_img"
                   v-on:mouseover="lj_info($event, info.lb_qd.itmes)"
                   v-on:mouseout="removeActive($event)"
                   data-id="lb_qd"
                   :src="info.lb_qd.img"
                   style="width:28px;height:28px;">
            </div>
            <div>
              <span v-if="info.jlzt.qd == 0"
                    class="an_on"></span>
              <span v-if="info.jlzt.qd == 2"
                    style="position:relative;left:-4px;top:9px;">已领取</span>
              <span v-if="info.jlzt.qd == 1"
                    class="an_dlq"
                    @click="receive('qd')"></span>
            </div>
          </li>
          <!-- 祝福达成礼包 -->
          <li style="position:relative;left:59px;">
            <div>
              <img class="lj jl_img"
                   data-id="lb_dc"
                   v-on:mouseover="lj_info($event, info.lb_dc.itmes)"
                   v-on:mouseout="removeActive($event)"
                   :src="info.lb_dc.img"
                   style="width:28px;height:28px;">
            </div>
            <div>
              <span v-if="info.jlzt.dc == 0"
                    class="an_on"></span>
              <span v-if="info.jlzt.dc == 2"
                    style="position:relative;left:-4px;top:9px;">已领取</span>
              <span v-if="info.jlzt.dc == 1"
                    class="an_dlq"
                    @click="receive('dc')"></span>
            </div>
          </li>
        </ul>
      </div>
      <!-- 礼包区end -->

      <!-- 集福区 -->
      <div class="jitzit">
        <ul>
          <li>
            <div class="iockj">
              <div class="kjjl jl1">
                <span v-if="info.fu.fu1.fu == ''">
                  <img src="../../../public/static/plug/setf/static/images/ioc/0.png"
                       style="left:16px;">
                  <span class="fl_num"
                        style="left:7px;top:21px;">0</span>
                </span>
                <span v-if="info.fu.fu1.fu != ''">
                  <img src="../../../public/static/plug/setf/static/images/ioc/8.png"
                       style="left:16px;">
                  <span class="fl_num"
                        style="left:7px;top:21px;">{{ info.fu.fu1.num }}</span>
                </span>
              </div>

              <div class="kjjl jl2">
                <span v-if="info.fu.fu2.fu == ''">
                  <img src="../../../public/static/plug/setf/static/images/ioc/1.png"
                       style="left:17px;">
                  <span class="fl_num"
                        style="left:9px;top:21px;">0</span>
                </span>
                <span v-if="info.fu.fu2.fu != ''">
                  <img src="../../../public/static/plug/setf/static/images/ioc/9.png"
                       style="left:17px;">
                  <span class="fl_num"
                        style="left:9px;top:21px;">{{ info.fu.fu2.num }}</span>
                </span>
              </div>

              <div class="kjjl jl3">
                <span v-if="info.fu.fu3.fu == ''">
                  <img src="../../../public/static/plug/setf/static/images/ioc/2.png"
                       style="left:16px;top:14px;">
                  <span class="fl_num"
                        style="left:8px;top:24px;">0</span>
                </span>
                <span v-if="info.fu.fu3.fu != ''">
                  <img src="../../../public/static/plug/setf/static/images/ioc/10.png"
                       style="left:16px;top:14px;">
                  <span class="fl_num"
                        style="left:8px;top:32px;">{{ info.fu.fu3.num }}</span>
                </span>
              </div>

              <div class="kjjl jl4">
                <span v-if="info.fu.fu4.fu == ''">
                  <img src="../../../public/static/plug/setf/static/images/ioc/3.png"
                       style="left:16px;">
                  <span class="fl_num"
                        style="left:8px;top:21px;">0</span>
                </span>
                <span v-if="info.fu.fu4.fu != ''">
                  <img src="../../../public/static/plug/setf/static/images/ioc/11.png"
                       style="left:16px;">
                  <span class="fl_num"
                        style="left:8px;top:21px;">{{ info.fu.fu4.num }}</span>
                </span>
              </div>

              <div class="jlzb"
                   style="position:relative;left:23px;top:-8px;">
                <div>
                  <img class="lj"
                       data-id="lb_zf1"
                       v-on:mouseover="lj_info($event, info.lb_zf1.itmes)"
                       v-on:mouseout="removeActive($event)"
                       :src="info.lb_zf1.img"
                       style="position:relative;left:7px;top:18px;width:28px;height:28px;">
                  <span v-if="info.jlzt.zf1 == 0"
                        class="jf_on"></span>
                  <span v-if="info.jlzt.zf1 == 2"
                        style="position:relative;left:23px;top:20px;">已领取</span>
                  <span v-if="info.jlzt.zf1 == 1"
                        class="jf_dlq"
                        @click="receive('zf1')"></span>
                </div>
              </div>
            </div>
          </li>
          <li>
            <div class="iockj">
              <div class="kjjl jl5">
                <span v-if="info.fu.fu5.fu == ''">
                  <img src="../../../public/static/plug/setf/static/images/ioc/4.png"
                       style="left:17px;">
                  <span class="fl_num"
                        style="left:7px;top:21px;">0</span>
                </span>
                <span v-if="info.fu.fu5.fu != ''">
                  <img src="../../../public/static/plug/setf/static/images/ioc/12.png"
                       style="left:17px;">
                  <span class="fl_num"
                        style="left:7px;top:21px;">{{ info.fu.fu5.num }}</span>
                </span>
              </div>
              <div class="kjjl jl6">
                <span v-if="info.fu.fu6.fu == ''">
                  <img src="../../../public/static/plug/setf/static/images/ioc/5.png"
                       style="left:15px;">
                  <span class="fl_num"
                        style="left:9px;top:21px;">0</span>
                </span>
                <span v-if="info.fu.fu6.fu != ''">
                  <img src="../../../public/static/plug/setf/static/images/ioc/13.png"
                       style="left:15px;">
                  <span class="fl_num"
                        style="left:9px;top:21px;">{{ info.fu.fu6.num }}</span>
                </span>
              </div>
              <div class="kjjl jl7">
                <span v-if="info.fu.fu7.fu == ''">
                  <img src="../../../public/static/plug/setf/static/images/ioc/6.png"
                       style="left:16px;top:14px;">
                  <span class="fl_num"
                        style="left:8px;top:21px;">0</span>
                </span>
                <span v-if="info.fu.fu7.fu != ''">
                  <img src="../../../public/static/plug/setf/static/images/ioc/14.png"
                       style="left:16px;top:14px;">
                  <span class="fl_num"
                        style="left:8px;top:21px;">{{ info.fu.fu7.num }}</span>
                </span>

              </div>
              <div class="kjjl jl8">
                <span v-if="info.fu.fu8.fu == ''">
                  <img src="../../../public/static/plug/setf/static/images/ioc/7.png"
                       style="left:18px;">
                  <span class="fl_num"
                        style="left:8px;top:21px;">0</span>
                </span>
                <span v-if="info.fu.fu8.fu != ''">
                  <img src="../../../public/static/plug/setf/static/images/ioc/15.png"
                       style="left:18px;">
                  <span class="fl_num"
                        style="left:8px;top:21px;">{{ info.fu.fu8.num }}</span>
                </span>
              </div>
              <div class="jlzb"
                   style="position:relative;left:23px;top:3px;">
                <div>
                  <img class="lj"
                       data-id="lb_zf2"
                       v-on:mouseover="lj_info($event, info.lb_zf2.itmes)"
                       v-on:mouseout="removeActive($event)"
                       v-bind:src="info.lb_zf2.img"
                       style="position:relative;left:7px;top:16px;width:28px;height:28px;">
                  <span v-if="info.jlzt.zf2 == 0"
                        class="jf_on"></span>
                  <span v-if="info.jlzt.zf2 == 2"
                        style="position:relative;left:25px;top:20px;">已领取</span>
                  <span v-if="info.jlzt.zf2 == 1"
                        class="jf_dlq"
                        @click="receive('zf2')"></span>
                </div>
              </div>
            </div>
          </li>
        </ul>
        <p style="text-align:center;margin-top:11px;">获取集福奖励（{{ info.fucount }}/2）</p>
      </div>
      <!-- 集福区end -->
      <!-- 任务区 -->
      <div class="dibujl"
           style="text-align:center;margin-top:46px;">
        <div v-if="info.fube.taskState == 0">
          <p>无任务</p>
        </div>
        <div v-if="info.fube.taskState == 1">
          <p>通关【{{ info.fube.fuBeName }}】副本/次 有{{info.probability}}%概率获得随机1张福</p>
          <p>日挑战上限（{{ info.fube.dqNum }} / {{ info.fube.num }}）次</p>
        </div>
      </div>
      <!-- 任务区end -->
    </div>
    <div class="iteminfo">
      <div style="text-align:center;">物品礼包</div>
      <div v-for="(itmec, i) in itme_show_data"
           :key="i"
           style="padding:0px 6px;">{{ itmec.name }}x{{ itmec.num }}
      </div>
    </div>
    <div class="titlehover">……</div>
    <div class="alert">
      <!-- 弹出框 -->
      <div class="alert_bg"
           :style="MessageDisplay">
        <div class="alert_main">
          <div class="alert_title"><span>提示</span></div>
          <div class="alert_con">
            <div class="alert_com">
              <span>{{ MessageMsg }}</span>
              <div class="btn"
                   @click="CloseAlert">关闭</div>
            </div>
          </div>
          <div class="alert_bottom"><span></span></div>
        </div>
      </div>
      <!-- 弹出框end -->
      <!-- 登陆框 -->
      <div class="alert_bg"
           :style="LoginDisplay">
        <div class="alert_main"
             style="margin-top:86px;width:350px;">
          <div class="alert_title"><span>绑定|切换</span></div>
          <div class="alert_con">
            <div class="alert_com">
              <p style="margin-bottom:6px;display:flex;"><span style="padding:0px 0px 0px 60px;">游戏账号：</span><input type="text"
                       v-model="login_info.username"
                       class="service__input"></p>
              <p style="margin-bottom:6px;display:flex;"><span style="padding:0px 0px 0px 60px;">游戏密码：</span><input type="password"
                       v-model="login_info.password"
                       class="service__input"></p>
              <p style="margin-bottom:6px;display:flex;"><span style="padding:0px 0px 0px 60px;">绑定角色：</span>
                <select v-model="login_info.role"
                        style="width:154px;">
                  <option v-for="(itmec, i) in role_data"
                          :key="i"
                          :value="itmec.charac_no">
                    {{ itmec.charac_name }}</option>
                </select>
                <span style="background:#7478e4;padding:3px 10px;margin-left:2px;border-radius:4%;"
                      @click="getRole">获取</span>
              </p>
              <div>
                <span style="padding:0px 0px;color:#f90000;"><span style="display:inline-block;padding:0px 0px;">{{ LoginError }}</span></span>
              </div>
              <div style="text-align:center;padding:10px 0px">
                <div class="btn"
                     @click="login"
                     style="margin:0px 0px;display:inline-block;">绑定</div>
                <div class="btn"
                     @click="LoginDisplay = 'display:none'"
                     style="margin:0px 0px;display:inline-block;">取消</div>
              </div>
            </div>
          </div>
          <div class="alert_bottom"><span></span></div>
        </div>
      </div>
    </div>
  </div>

</template>
<script>
//import {game} from '../../../public/static/plug/setf/static/js/game.js'
import Vue from 'vue'
export default {
  name: "Setf",
  data () {
    return {
      MessageDisplay: 'display:none', //弹出框显示
      LoginDisplay: 'display:none', //登陆弹出框
      LoginError: '',
      MessageMsg: '', //弹出框消息
      Loading: false,
      info: {
        m_id: 0, //账号uid
        charac_no: 0, //角色ID
        charac_name: '', //角色名称
        on_off: '',
        lb_qd: {
          img: '',
          itmes: [],
        },
        lb_dc: {
          img: '',
          itmes: [],
        },
        lb_zf1: {
          img: '',
          itmes: [],
        },
        lb_zf2: {
          img: '',
          itmes: [],
        },
        jlzt: {
          qd: 0,
          dc: 0,
          zf1: 0,
          zf2: 0,
        },
        fu: {
          fu1: { fu: '', num: 0, },
          fu2: { fu: '', num: 0, },
          fu3: { fu: '', num: 0, },
          fu4: { fu: '', num: 0, },
          fu5: { fu: '', num: 0, },
          fu6: { fu: '', num: 0, },
          fu7: { fu: '', num: 0, },
          fu8: { fu: '', num: 0, },
        },
        fucount: 0,
        fube: {
          fuBeName: '',
          num: 0,
          dqNum: 0,
          taskState: 1,
        },
        probability:0,
      },
      login_info: {
        username: '',
        password: '',
        role: '',
      },
      itme_show_data: [], //展示物品用
      role_data: [],
    };
  },
  created () {
    this.getInfo()
  },
  methods: {
    //获取基本配置信息
    getInfo () {
      this.Loading = true
      this.axios({
        // 默认请求方式为get
        method: 'post',
        url: Vue.prototype.config.webplugurl + '/setf/getInfo',
        // 传递参数
        data: {
          key: this.$route.query.key,
          keyid:this.$route.query.id,
        },
        responseType: 'json'
      }).then(response => {
        this.Loading = false
        if (response.data.code == 200) {
          this.info = response.data.info
        } else {
          this.PopMessage(true, response.data.msg)
        }
      }).catch(error => {
        this.Loading = false
        // 请求失败，
        console.log(error)
      });
    },
    //登陆
    login () {
      if (this.Loading == true) {
        return false
      }
      this.Loading = true
      this.axios({
        // 默认请求方式为get
        method: 'post',
        url: Vue.prototype.config.webplugurl + '/PlugLogin',
        // 传递参数
        data: {
          key: this.$route.query.key,
          keyid:this.$route.query.id,
          username: this.login_info.username,
          password: this.login_info.password,
          role: this.login_info.role,
        },
        responseType: 'json'
      }).then(response => {
        this.Loading = false
        if (response.data.code == 200) {
          this.LoginDisplay = 'display:none'
          this.getInfo()
        } else {
          this.LoginError = response.data.msg
        }
      }).catch(error => {
        this.Loading = false
        // 请求失败，
        console.log(error)
      });
    },
    //获取角色
    getRole () {
      if (this.Loading == true) {
        return false
      }
      this.Loading = true
      this.axios({
        // 默认请求方式为get
        method: 'post',
        url: Vue.prototype.config.webplugurl + '/getRole',
        // 传递参数
        data: {
          key: this.$route.query.key,
          keyid:this.$route.query.id,
          username: this.login_info.username,
          password: this.login_info.password,
        },
        responseType: 'json'
      }).then(response => {
        this.Loading = false
        if (response.data.code == 200) {
          this.role_data = response.data.info
        } else {
          this.LoginError = response.data.msg
        }
      }).catch(error => {
        this.Loading = false
        // 请求失败，
        console.log(error)
      });
    },
    //领取
    receive (type) {
      if (this.Loading == true) {
        return false
      }
      this.Loading = true
      this.axios({
        // 默认请求方式为get
        method: 'post',
        url: Vue.prototype.config.webplugurl + '/setf/SetfReceive',
        // 传递参数
        data: {
          key: this.$route.query.key,
          keyid:this.$route.query.id,
          lqtype: type,
        },
        responseType: 'json'
      }).then(response => {
        this.Loading = false
        if (response.data.code == 200) {
          this.PopMessage(true, response.data.msg)
        } else {
          this.PopMessage(true, response.data.msg)
        }
      }).catch(error => {
        this.Loading = false
        // 请求失败，
        console.log(error)
      });
    },
    //弹出框状态
    PopMessage (type, msg) {
      this.MessageDisplay = "display:none"
      this.MessageMsg = msg
      if (type == true) {
        this.MessageDisplay = "display:block"
      } else {
        this.getInfo()
      }
    },
    //关闭窗口
    CloseAlert () {
      this.PopMessage(false, '')
    },
    lj_info ($event, itmes) {
      this.itme_show_data = itmes
      var container = document.getElementsByClassName("iteminfo");
      if (typeof (container) != "undefined") {
        container[0]
        var x = $event.x
        var y = $event.y

        if (x > 1167) {
          console.log("11")
          container[0].style.left = (x - 77) + 'px'
        } else {
          container[0].style.left = (x - 73) + 'px'
        }
        container[0].style.top = y + 'px'
        container[0].style.display = 'block'
        let item_h = container[0].offsetHeight + container[0].style.top;
        if (item_h > 435) {
          y -= (item_h - 435);
          container[0].style.top = y + 'px'
        }
      }
    },
    removeActive ($event) {
      console.log($event)
      var container = document.getElementsByClassName("iteminfo");
      if (typeof (container) != "undefined") {
        container[0].style.display = 'none'
      }
    },
  },
};
</script>


<style scoped>
@import "../../../public/static/plug/setf/static/css/game.css";
</style>

<style>
#root,
body,
html {
  min-width: 0px;
}
</style>


